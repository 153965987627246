<template>
  <v-app dark>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="miniVariant"
      :clipped="clipped"
      fixed
      app
    >
      <div style="width: 100%; text-align: center">
        <img :src="imagelogo" width="100%" />
      </div>
      <div class="pa-2 pr-9" style="color: navy">
        Hi {{ $store.state.eventInfo.UserName }}
      </div>
      <v-divider></v-divider>
      <v-list dense>
        <template v-for="item in Adminmenu">
          <v-list-group
            v-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon=""
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              link
              :to="child.to"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.text" link :to="item.to">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item
          @click="AppTransfer"
          v-if="$store.state.eventInfo.venuetype == 'Central'"
        >
          <v-list-item-action>
            <v-icon>mdi-file-swap-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Application Transfer </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

       

        


       

        <v-list-item @click="logout">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Logout </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      :clipped-left="clipped"
      dense
      fixed
      app
      flat
      color="primary"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

      <v-toolbar-title
        style="text-align: left; font-size: 16px"
        v-text="$store.state.pageTitle"
      />
      <v-spacer></v-spacer>
      <v-btn text @click="openVenueModel">
        <v-icon left>mdi-map-marker</v-icon>
        {{ $store.state.eventInfo.VenueName }}
      </v-btn>
    </v-app-bar>
    <v-main>
      <div style="background-color: #e9e9e9; min-height: 100%">
        <router-view></router-view>
      </div>
    </v-main>
    <v-footer dark class="pt-1 pb-1">
      <v-card width="100%">
        <v-card-actions class="pa-1" style="font-size: 0.7rem">
          <span>&copy; {{ new Date().getFullYear() }}</span>
          <v-spacer></v-spacer>
          Powered By
          <img src="/DNA-Logo-mono.png" height="45px" class="pl-3" alt="" />
        </v-card-actions>
      </v-card>
    </v-footer>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="400"
      v-model="modVenueOpened"
    >
      <v-card>
        <v-toolbar color="primary" flat dark dense>Change Venue</v-toolbar>
        <v-card-text class="pt-4">
          <v-select
          outlined
            :items="$store.state.eventInfo.Venues"
            label="Select Venue"
            item-text="venue_name"
            item-value="venue_id"
            v-model="selVenue"
            return-object            
          >
          </v-select>
        </v-card-text>
         <v-card-actions class="justify-center">
          <v-btn color="primary" class="px-5" large @click="changeVenue"
            ><v-icon right>mdi-content-save</v-icon> Save</v-btn
          >
        </v-card-actions>       
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      imagelogo: "",
      switch1: false,
      clipped: false,
      drawer: true,
      fixed: false,
      display_event_name: "",
      display_venue_name: "",
      modVenueOpened: false,
      selVenue: {},
      Adminmenu: [
        
      ],

      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: "Vuetify.js",
    };
  },
  mounted() {
    this.BindAdminmenu();
    this.imagelogo =
      this.$apiPath +
      "assets/" +
      this.$store.state.eventInfo.EventUrl +
      "/logo.jpg";
    if (
      this.$store.state.eventInfo.UType == "Admin" ||
      this.$store.state.eventInfo.UType == "CentralAdmin" ||
      this.$store.state.eventInfo.UType == "SubAdmin"
    ) {
      console.log("login success");
    } else {
      this.$store.commit("setLogout");
      this.$router.push("/" + this.$store.state.eventInfo.EventUrl + "/login");
      return;
    }

    this.display_event_name = this.$store.state.eventInfo.EventName;
  },
  methods: {
    BindAdminmenu(){
        if (this.$store.state.eventInfo.UType == "SubAdmin")
     {
this.Adminmenu=[
  { icon: "mdi-apps", text: "Dashboard", to: "/admin/dashboard" },
        {
          icon: "mdi-chart-box-outline",
          text: "Application Summary",
          to: "/admin/appsummary",
        },
        {
          icon: "mdi-chart-box-plus-outline",
          text: "Application Manager",
          to: "/admin/appreport",
        },

        {
          icon: "mdi-account-cancel",
          text: "Active / Cancel Card",
          to: "/admin/appcancel",
        },

        {
          icon: "mdi-chevron-up",
          "icon-alt": "mdi-chevron-down",
          text: "Reports",
          model: false,
          children: [
            {
              icon: "mdi-file-chart",
              text: "Basic Reports",
              to: "/admin/rptbasicreport",
            },
            {
              icon: "mdi-file-chart",
              text: "Complete Reports",
              to: "/admin/rptcompletereport",
            },
            {
              icon: "mdi-file-chart",
              text: "Covid Reports",
              to: "/admin/rptcovidreport",
            },
            {
              icon: "mdi-file-chart",
              text: "Police Reports",
              to: "/admin/rptpolicereport",
            },
            {
              icon: "mdi-file-chart",
              text: "Card pickup Reports",
              to: "/admin/rptcardpickupReport",
            },
            {
              icon: "mdi-file-chart",
              text: "Scann Reports",
              to: "/admin/rptscannReport",
            },
            
          ],
        },
]
     }
     else
     {
this.Adminmenu=[
  { icon: "mdi-apps", text: "Dashboard", to: "/admin/dashboard" },
        { icon: "mdi-bank", text: "Company Master", to: "/admin/company" },
        {
          icon: "mdi-chart-box-outline",
          text: "Application Summary",
          to: "/admin/appsummary",
        },
        {
          icon: "mdi-chart-box-plus-outline",
          text: "Application Manager",
          to: "/admin/appreport",
        },

        {
          icon: "mdi-account-cancel",
          text: "Active / Cancel Card",
          to: "/admin/appcancel",
        },

        {
          icon: "mdi-chevron-up",
          "icon-alt": "mdi-chevron-down",
          text: "Reports",
          model: false,
          children: [
            {
              icon: "mdi-file-chart",
              text: "Basic Reports",
              to: "/admin/rptbasicreport",
            },
            {
              icon: "mdi-file-chart",
              text: "Complete Reports",
              to: "/admin/rptcompletereport",
            },
            // {
            //   icon: "mdi-file-chart",
            //   text: "Covid Reports",
            //   to: "/admin/rptcovidreport",
            // },
            {
              icon: "mdi-file-chart",
              text: "Police Reports",
              to: "/admin/rptpolicereport",
            },
            // {
            //   icon: "mdi-file-chart",
            //   text: "Card pickup Reports",
            //   to: "/admin/rptcardpickupReport",
            // },
            // {
            //   icon: "mdi-file-chart",
            //   text: "Scann Reports",
            //   to: "/admin/rptscannReport",
            // },
            
          ],
        },
]
     }
    },
    openVenueModel: function () {
      if (this.$store.state.eventInfo.Venues.length > 1) {
        this.modVenueOpened = true;
      }
    },
    changeVenue: function () {
      this.$store.commit("changeVenue", this.selVenue);
      this.modVenueOpened = false;
      this.$router.push("/admin/dashboard").catch(() => {});
      window.location.reload(true);
    },
    changetheme: function () {
      if (this.switch1 == true) {
        this.$vuetify.theme.dark = true;
      }
      if (this.switch1 == false) {
        this.$vuetify.theme.dark = false;
      }
    },
    logout: function () {
      this.$store.commit("setLogout");
      this.$router.push("/" + this.$store.state.eventInfo.EventUrl + "/login");
    },

    AppTransfer: function () {
      //this.$store.commit("setLogout");
      this.$router.push("/admin/apptransfer");
    },

    preprindata: function () {
      //this.$store.commit("setLogout");
      this.$router.push("/admin/rptzipReport");
    },

    AppEdit: function () {
      this.$router.push("/admin/appedit");
    },
  },
};
</script>
<style>
.cardHeader {
  background-color: rgb(226, 240, 252);
  padding: 8px;
  padding-left: 10px;
  border-left: 10px solid rgb(75, 35, 250);
  font-weight: 500;
  font-size: 1.05rem;
}
</style>

